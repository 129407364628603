// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht von Familie Forrer"
        meta={[
          { name: 'description', content: 'Familie Forrer erzählt, warum sie sich für eine Wärmepumpe entschieden hat und welche Vorteile sie bietet. Gleich reinschauen!' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='57DHsMu5lQ73utaAZ9Dunv-lUUEIAOx05O5Vvq3yumNw'
          id='familie-forrer-elsau-zh'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "lUUEIAOx05O5Vvq3yumNw",
    "updatedAt": "2022-07-21T07:08:14.681Z",
    "title": "FAMILIE FORRER, ELSAU ZH",
    "size": "Groß",
    "teaser": "Die knapp 20-jährige Gasheizung der Familie Forrer musste altersbedingt ausgetauscht werden. Nach diversen Beratungen von verschiedenen Wärmepumpen-Anbietern, stiess die Familie auf die Heizungsmacher AG und erfreute sich an der bedürfnisorientierten und markenunabhängigen Beratung.",
    "image": {
        "description": "Hintergrundbild Referenz-Story Familie Forrer aus Elsau ZH",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/5i4TRZHtaq0o60T9gfEyi8/b4bda06a50633c175644b2dfe70a03e6/01---Format---Titelbild-Hero---Gross.png"
    },
    "links": [
        {
            "type": "link",
            "id": "5Rv4CldRK0aI4UGakKsISC",
            "updatedAt": "2022-04-01T07:44:51.656Z",
            "label": "Zur Referenz-Übersicht",
            "page": {
                "slug": "referenzen"
            }
        }
    ],
    "anchorName": "familie-forrer-elsau-zh"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='57DHsMu5lQ73utaAZ9Dunv-61shG5EfyLv4qLwuQMLFKI'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "61shG5EfyLv4qLwuQMLFKI",
    "updatedAt": "2022-06-22T13:52:45.077Z",
    "title": "Familie Forrer hätte nie gedacht, dass ihnen das naheliegende Bächli einen Strich durch die Rechnung machen würde.",
    "steps": [
        {
            "type": "howToStep",
            "id": "zsgNuMZiudWRkHZ33Rpkd",
            "updatedAt": "2022-08-04T07:48:13.286Z",
            "title": "«Das Heizungsmacher-Team ermöglichte uns eine bestehende Anlage bei einem Heizungsmacher-Kunden anzuschauen.»",
            "description": "<p>Mindestens drei Wärmepumpen-Anbieter wurden von Familie Forrer für eine <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz</a>-Beratung aufgeboten. Familie Forrer’s Wunsch war ursprünglich, eine <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-erdsondenbohrung/\">Erdsonden-Wärmepumpe</a> zu installieren. René Forrer erklärt: «Die Vorabklärungen mit der Gemeinde haben uns aber aufgezeigt, dass eine Erdsondenbohrung aufgrund des nahegelegenen Bächlis nicht möglich sei.»</p>\n<p>«Das Heizungsmacher-Team ermöglichte uns eine bestehende Anlage bei einem <a href=\"https://www.heizungsmacher.ch/referenzen/\">Heizungsmacher-Kunden</a> anzuschauen und somit konnten wir alle offenen Fragen und Bedenken wegen der Lärmkulisse beseitigen» erklärt René Forrer. «Nach dieser sehr bedürfnisorientierten Beratung entschieden wir uns schliesslich für eine <a href=\"https://www.heizungsmacher.ch/wissen/was-ist-eine-luft-waermepumpe/#innen-oder-aussenaufstellung\">aussen aufgestellte Wärmepumpe</a> von NIBE. Auch nach zwei Jahren können wir behaupten, dass unsere Entscheidung, die aussen aufgestellte Wärmepumpe durch die Heizungsmacher AG installieren zu lassen, die richtige war.»</p>\n",
            "image": {
                "description": "Ein Bild von Familie Forrers Haus",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3ofazjYP6vPGykG9f9U0Sl/13edb5d3d47a619ddc0b4533be57fa86/01---Format---Referenz-Bild-im-Anleitungsschritt5.png"
            }
        },
        {
            "type": "howToStep",
            "id": "7Ey9hgMfyiMuwKCWMSyii8",
            "updatedAt": "2021-12-29T07:17:48.778Z",
            "title": "René Forrer: «Die Anlage funktioniert einfach»",
            "description": "<p>Bekanntlich bringt eine <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-waermepumpe/\">Wärmepumpe</a> viele Vorteile mit sich, wie zum Beispiel die geringen <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/#die-kosten-einer-waermepumpe-auf-einen-blick\">Betriebskosten</a> als auch die praktische Bedienung der Anlage. Das Letztere scheint Familie Forrer sehr zu geniessen. René Forrer ist begeistert: «Wir müssen uns um nichts kümmern, die Anlage funktioniert einfach».</p>\n",
            "image": {
                "description": "Ein Bild von Familie Forrer's Wärmepumpe NIBE F2120-12",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5ghPUVuNSomaLetvEG4e26/b3aa6520ddd1885186a7e984a480d51d/01---Format---Referenz-Bild-im-Anleitungsschritt2.png"
            }
        },
        {
            "type": "howToStep",
            "id": "2KMm4FnWiiVpgAsHAHPlOR",
            "updatedAt": "2022-01-14T09:22:24.849Z",
            "title": "Eine richtig eingestellte Heizkurve ist wichtig für Wärmepumpen-Besitzer",
            "description": "<p>Heizungsmacher AG ermittelt die Wohnbedürfnisse der Kunden und stellt bei jeder Installation einer Wärmepumpe dementsprechend die Heizkurve ein - wie auch bei Familie Forrer. Die Heizkurve ermöglicht, dass die Aussen- und Vorlauftemperatur aufeinander abgestimmt werden um das ständige An- und Ausschalten des Gerätes zu vermeiden. Demnach steigt die Effizienz der Wärmepumpe deutlich und Familie Forrer darf sich an den <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/\">niedrigen Stromkosten</a> erfreuen.</p>\n<p>René Forrer ergänzt: «Wir können die Raumtemperatur mit dem Online-Monitoring ganz einfach nach unseren Bedürfnissen einstellen - auch vom Ausland aus».</p>\n",
            "image": {
                "description": "Ein Bild von Familie Forrer's NIBE Inneneinheit VVM 500",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3ZYGJc0RKt8tVA5cVsCej5/ef239d718f9b20d903f47d4b10827d87/01---Format---Referenz-Bild-im-Anleitungsschritt.png"
            }
        },
        {
            "type": "howToStep",
            "id": "1WkEVpQktiL9Eym3lTIaer",
            "updatedAt": "2022-01-13T12:41:28.023Z",
            "title": "«Sie gseht nöd ganz so wüescht us»",
            "description": "<p>Wir wissen, dass sich über Geschmäcker nicht streiten lässt und unsere <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Wärmepumpen-Spezialisten</a> sind bestrebt, das Erscheinungsbild und den Aufstellort der Wärmepumpen an die Bedürfnisse der Hausbesitzer anzupassen. Auf unsere Frage, wie er das Erscheinungsbild seiner Wärmepumpe empfindet, antwortet Herr Forrer auf eine äusserst charmante Weise: «Sie gseht nöd ganz so wüescht us» und darauf folgten Gelächter. «Sie steht sowieso nur am Haus zu und nicht im Garten, daher stört sie niemanden», fügt er hinzu.</p>\n<p>Wir möchten uns hiermit nochmals bei Familie Forrer für das freundliche Interview und ihre wertvolle Erfahrung bedanken und wünschen ihnen mit der neuen Wärmepumpe wohlig warme Wintertage.</p>\n",
            "image": {
                "description": "Platzhalter wenn keine Fotos vorhanden sind",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3B3285LKZfZybmNKIcT8s6/df9d4ae9b8d4ff14b51841a9942292fb/Platzhalter-Bild-Anleitungsschrift-transparent.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='57DHsMu5lQ73utaAZ9Dunv-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
